<template>
  <v-container>
    <h1 class="mb-4">Google Gemini Pro</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Sandbox</v-tab>
        <v-tab>Background</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Proof of Concept ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Proof of Concept</h2>
            <p>In Development</p>
            <!-- <v-row class="pa-2">
              <v-col v-for="usecase in usecases" :key="usecase.name" cols="3" xs="12" sm="6" md="3">
                <v-card class="mx-auto">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                  >
                  </v-img>
                  <v-divider></v-divider>
                  <v-card-title class="pb-0">
                    {{ usecase.name }}
                  </v-card-title>
                  <v-card-text class="text--primary">
                    {{ usecase.description }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="orange" text>Share</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row> -->
          </v-tab-item>
          <!-- ------------ Sandbox ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Sandbox</h2>
            <p>In Development</p>
          </v-tab-item>
          <!-- ------------ Background ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Background</h2>
            <p>In Development</p>
            <!-- <ul>
              <li>Equipment was installed before a cataloging system was implemented.</li>
              <li>In order to identify a piece of equipment the information from a plaque must be gathered.</li>
              <li>Plaques are often located in hard to reach places.</li>
              <li>Hydro Ottawa has utilized drones to capture photos of this equipment.</li>
              <li>A human then transcribe this information from the photos into a spreadsheet</li>
              <li>Another human then validates the input data</li>
            </ul>
            <h4 class="mt-6">Objective</h4>
            <ul>
              <li>Automate the extraction process</li>
              <li>Use AI to capture the data</li>
              <li>Train the model on the existing validated data sets</li>
              <li>Output the desired information directly into the system-of-record</li>
            </ul> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="imageDialog" width="800">
      <v-img
        :src="imageDialogImage"
        max-width="800"
      ></v-img>
    </v-dialog>
  </v-container>
</template>
<script>
export default ({
  name: 'GeminiExperiment',
  data: () => ({
    tab: 0,
    imageDialog: false,
    imageDialogImage: '',
    usecases: [
      {
        name: 'Personal Assistant',
        image: '',
        description: 'Execute complex tasks with natural language. '
      },  
      {
        name: 'General Enquiries',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-screenshot.png?alt=media&token=1ee51680-c273-4086-be38-7bd5d5823cdf',
        description: 'The bot can answer general questions including those specific to Hydro Ottawa and its services.'
      },
      {
        name: 'System Interface',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-outages-command.png?alt=media&token=ee126499-e7d3-471a-bd75-151aca301a1d',
        description: 'The bot offers custom commands that can be used to execute common tasks quickly and efficiently.'
      },
      {
        name: 'Self-Service',
        image: '',
        description: ''
      },
    ]
  }),
  methods: {
    showImageDialog(image) {
      this.imageDialogImage = image
      this.imageDialog = true
    }
  }
})
</script>
<style lang="scss">

</style>