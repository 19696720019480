<template>
  <v-container>
    <h1 class="mb-4">Google Duet AI Pilot</h1>
    <v-card>
      <v-card-title>Objective</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        Coming soon...
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'DuetPilot',
  return: {
    
  }
})
</script>
<style lang="scss">

</style>