<template>
  <v-container>
    <h1 class="mb-4">Device Nameplate Automation Experiment</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Sandbox</v-tab>
        <v-tab>Background</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Proof of Concept ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Proof of Concept</h2>
            <v-row class="pa-2">
              <v-col v-for="(usecase, index) in usecases" :key="'case' + index" cols="12" xs="12" sm="6" md="3">
                <v-card class="mx-auto">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="usecase.image"
                    @click="showDialog(index)"
                  >
                  </v-img>
                  <v-divider></v-divider>
                  <v-card-title @click="showDialog(index)">
                    {{ usecase.title }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ usecase.nameplate ? 'Nameplate' : 'Equipment'}}
                  </v-card-subtitle>
                  <v-card-text class="text--primary">
                    {{ usecase.summary }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- ------------ Sandbox ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Sandbox</h2>
            <p>In Development</p>
          </v-tab-item>
          <!-- ------------ Background ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Background</h2>
            <ul>
              <li>Equipment was installed before a cataloging system was implemented.</li>
              <li>In order to identify a piece of equipment the information from a plaque must be gathered.</li>
              <li>Plaques are often located in hard to reach places.</li>
              <li>Hydro Ottawa has utilized drones to capture photos of this equipment.</li>
              <li>A human then transcribe this information from the photos into a spreadsheet</li>
              <li>Another human then validates the input data</li>
            </ul>
            <h4 class="mt-6">Objective</h4>
            <ul>
              <li>Automate the extraction process</li>
              <li>Use AI to capture the data</li>
              <li>Train the model on the existing validated data sets</li>
              <li>Output the desired information directly into the system-of-record</li>
            </ul>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-if="selectedUseCase" v-model="imageDialog" width="800">
      <v-img
        :src="selectedUseCase.image"
        max-width="800"
      ></v-img>
    </v-dialog>
    <v-dialog v-if="selectedUseCase" v-model="useCaseDialog" width="800">
      <v-card class="text-left">
        <div class="d-flex flex-row">
          <div class="d-flex flex-column flex-2 pa-6">
            <v-card-title>
              {{ selectedUseCase.title }}
            </v-card-title>
            <v-card-text>
              <img @click="showImage()" :src="selectedUseCase.image" width="100%">
              <div class="mt-8 mb-6">
                <h3 class="mb-6">Detailed Summary</h3>
                <!-- {{ selectedUseCase.description }} -->
                <div v-html="selectedUseCase.description"></div>
              </div>
            </v-card-text>
          </div>
          <div class="d-flex flex-column flex-1 pa-6 white--text" style="background: black">
            <h4 class="mb-6">Technical Details</h4>
            <div v-if="selectedUseCase.text" v-html="selectedUseCase.text"></div>
            <p v-else class="font-italic">No nameplate found.</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default ({
  name: 'NameplateAutomationExperiment',
  data: () => ({
    tab: 0,
    imageDialog: false,
    useCaseDialog: false,
    selectedUseCase: null,
    usecases: [
      {
        "title": "Transformer Nameplate Information",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FPXL_20231129_143532918.jpg?alt=media&token=15ebd914-ead9-4da2-b754-e3d47e884fff",
        "summary": "The image displays a transformer nameplate with detailed specifications and a diagram, affixed to the body of the transformer.",
        "description": "The technical photo shows the nameplate of a pole-mount type transformer designed for 60 Hz and 65°C operation. It lists electrical specifications like phase, kVA rating, impedance, high and low voltages, and includes a caution about operating the tap switches only when de-energized.",
        "nameplate": true,
        "text": "<div><h2>POLEMOUNT TYPE ONAN 60 Hz 65°C</h2><p><strong>Serial No.:</strong> AB21919461-003<br><strong>1 PH, 75 kVA</strong><br><strong>Impedance at 85°C:</strong> 7.2%<br><strong>High Voltage:</strong> 2400/4160Y V<br><strong>Low Voltage:</strong> 347/600Y V<br><strong>Total Mass:</strong> 484 kg<br><strong>Volume of Oil:</strong> 163 L<br><strong>Less than:</strong> 2 PPM PCB</p><p><strong>Warning:</strong> Operate tap changer only when transformer is de-energized.</p><p>Manufactured by ABB, Canada.</p></div>"
      },
      {
        "title": "Electrical Transformer Nameplate",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FPXL_20231129_143524450.jpg?alt=media&token=965571e2-d5a8-4ab4-8292-3e3dd9d9ae71",
        "summary": "The photo shows the nameplate of an electrical transformer with technical specifications and a tested label.",
        "description": "The image depicts a close-up of a transformer nameplate providing essential information such as phase, KVA rating, impedance, high and low voltage ratings, and efficiency data. Additionally, there is a 'TESTED' sticker indicating that the transformer has undergone testing procedures.",
        "nameplate": true,
        "text": "<div><p><strong>Nameplate Information:</strong></p><p>POLEMOUNT TYPE ONAN 60Hz, 65°C<br>1 PH 75 KVA<br>IMPEDANCE AT 85°C: 4.6%<br>HV BIL: 60 x 95 kV<br>HIGH VOLTAGE: 4160 GrdY/2400 V<br>LOW VOLTAGE: 347/600Y V<br>SERIAL NO.: [Serial Number]<br>VOL. OF OIL: 158 l<br>ENERGY EFFICIENCY PER CAN/CSA C802.1<br><table><tr><th>T/C POSITION</th><th>D/V POSITION POS. 1</th><th>2</th><th>3</th><th>4</th><th>5</th></tr><tr><td>1</td><td>7770</td><td>7585</td><td>7400</td><td>7215</td><td>7030</td></tr></table><br>BUILT TO CSA C2.2 & ANSI C57.12.00<br>CAM TRAN MAN. REF. NO.: NKCBO20_32<br>STOCK NO.: 613609<br>MANUFACTURED BY CAM TRAN CO. LTD., COLBORNE, ONTARIO, CANADA</p><p><strong>Tested</strong></p></div>"
      },
      {
        "title": "Electrical Insulating Oil Filters",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_8012.JPG?alt=media&token=e81290aa-6cd7-46a2-8b8e-03f3e853b71b",
        "summary": "The photo displays two insulating oil filter units attached to an electrical apparatus, used for purifying the insulating oil.",
        "description": "This technical photo shows two cylindrical insulating oil filters in an electrical utility setup. These filters are part of a system to maintain the purity of the insulating oil used in electrical equipment, which is crucial for preventing contamination that could lead to electrical failures.",
        "nameplate": true,
        "text": "<div><p><strong>Filter Specifications:</strong></p><ul><li>Model: [Model Number]</li><li>Type: Insulating Oil Filter</li><li>Function: [Specific Function]</li></ul></div>"
      },
      {
        "title": "Transformer Specification Nameplate",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_8010.JPG?alt=media&token=3079bd0e-d0cf-4eea-b2b2-828a3b19a880",
        "summary": "The image shows a detailed nameplate from Virginia Transformer Corp, providing specifications for an automatic load tap-changing liquid-filled transformer.",
        "description": "The photo captures a nameplate affixed to a transformer, detailing its technical specifications such as serial number, voltage ratings, impedance, and winding material. Additionally, it provides tap changer settings, sound levels, and a schematic diagram of the transformer connections.",
        "nameplate": true,
        "text": "<div><h2>AUTOMATIC LOAD TAP-CHANGING LIQUID FILLED TRANSFORMER</h2><p><strong>Serial:</strong> 479000A008-C107A<br><strong>Phase:</strong> 3 PHASE 60 Hz<br><strong>Voltage:</strong> HV: 47900 Grd Y/27600 V, LV: 8320 Grd Y/4804 V<br><strong>Impedance:</strong> 5.95% AT 85°C<br><strong>MVA:</strong> 9/12<br><strong>Winding Material:</strong> COPPER<br><strong>Year Manufactured:</strong> 2014</p><p><strong>Weight:</strong> CORE & COILS 11539 kg (25420 lbs), TANK & FITTINGS 1805 kg (3979 lbs), TOTAL WEIGHT 13344 kg (29400 lbs)</p><p><strong>CAUTION:</strong> OPERATE MANUAL TAP CHANGER ONLY WHEN TRANSFORMER IS DE-ENERGIZED</p><p>Manufactured by Virginia Transformer Corp, Roanoke, VA, USA</p></div>"
      },
      {
        "title": "Electrical Substation Components",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_8022.JPG?alt=media&token=393394fc-e6b5-4c0a-8632-e506e0ad9cac",
        "summary": "The image showcases an outdoor electrical substation with various high-voltage equipment, including disconnectors and bushings.",
        "description": "This photo displays part of an electrical substation which includes equipment such as high-voltage disconnect switches, bushings, and a control cabinet. The switches are used to isolate parts of the substation for maintenance or safety reasons, and the bushings allow safe passage of high-voltage conductors through a grounded barrier.",
        "nameplate": true,
        "text": "<div><p><strong>Control Cabinet Information:</strong></p><ul><li>Manufacturer</li><li>Model</li><li>Electrical Ratings</li></ul></div>"
      },
      {
        "title": "Industrial Transformer Equipment",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_6291.JPG?alt=media&token=82427256-e90e-4cac-aef7-feefe1491824",
        "summary": "The photo captures an industrial transformer with various gauges and a nameplate, installed within a facility.",
        "description": "This technical photo shows an industrial transformer, characterized by its large size, metal construction, and green color. Visible are the gauges for monitoring the transformer's performance, pipes for cooling or insulating fluids, and a nameplate that provides detailed information about the transformer's specifications.",
        "nameplate": true,
        "text": "<p><strong>Transformer Details</strong></p><ul><li>Gauge readings</li><li>Manufacturer information</li><li>Operational specifications</li></ul>"
      },
      {
        "title": "Transformer Information Sheet",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_6269.JPG?alt=media&token=6f5590dd-e8b2-4025-bb42-b5079f6dfb51",
        "summary": "The photo captures an information sheet for a transformer, including site location, identification details, and technical specifications.",
        "description": "The image shows a filled-out information sheet for a transformer. It lists the site location as 'WALKLEY UZ', unit and serial numbers, sample date, syringe number, oil temperature, and various specifications of the transformer such as type, manufacturer, year, capacity, voltage ratings, oil type, volume, and cooling method.", 
        "nameplate": false,
        "text": "<p><strong>Site/Location:</strong> WALKLEY UZ<br><strong>Unit ID:</strong> UZT3<br><strong>Serial Number:</strong> T35968</p><p><strong>Sample Date:</strong> June 21, 2021<br><strong>Syringe#:</strong> 40988<br><strong>Oil Temp degC/F:</strong> 63°C</p><p><strong>Equipment Type:</strong> Transformer<br><strong>Mfr. Name:</strong> Federal Pioneer<br><strong>Mfr. Year:</strong> 1970</p><p><strong>kVA:</strong> 5/6.7<br><strong>kV (HV/LV):</strong> 13.2/4.16<br><strong>Oil Type:</strong> Mineral Oil<br><strong>Oil Volume (L):</strong> 2555</p><p><strong>Type (Conservater, Sealed, Free Breather,...):</strong> Conservator<br><strong>Cooling (ONAN/ONAF...):</strong> ONAF</p>"
      },
      {
        "title": "Transformer Nameplate Information",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_7261.JPG?alt=media&token=245b951c-d2ca-4314-b66d-1c5964d26594",
        "summary": "The photo shows the nameplate of a Canadian General Electric transformer, detailing specifications, diagrams, and electrical data.",
        "description": "The photograph captures the nameplate on a Canadian General Electric transformer, which includes technical data such as voltage ratings, KVA ratings, tap changer information, and impedance values, along with schematic diagrams representing the connections and layout of the transformer windings.",
        "nameplate": true,
        "text": "<h1>CANADIAN GENERAL ELECTRIC</h1><h2>TRANSFORMER</h2><p>5000/6667/7500 KVA 55/65°C 3 PHASE 60 Hz<br>3200-4160Y/2400 VOLTS</p><p>FULL WAVE IMPULSE LEVEL KV. 110<br>IMPULSE KV. 75<br>L.V. 110</p><p><strong>HIGH VOLTAGE</strong><br>KVA 5000 7500<br>VOLTS 13200 4160<br>AMPS 219 694</p><p><strong>LOW VOLTAGE</strong><br>KVA 5000 7500<br>VOLTS 4160<br>AMPS 694 1041</p><p>WARNING: TRANSFORMER MUST BE FILLED WITH<br>60,000 LBS. OF OIL BEFORE ENERGIZING. AVOID<br>DRAWING OIL SAMPLES UNTIL AFTER THE<br>TRANSFORMER HAS BEEN ENERGIZED 24 HOURS.<br>REFER TO INSTRUCTION BOOK. GGEL-2476</p><p>MADE IN CANADA AT GUELPH, ONTARIO</p>"
      },
      {
        "title": "High-Voltage Substation Equipment",
        "image": "https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/NameplateAutomation%2FIMG_6947.JPG?alt=media&token=c0eafe87-c535-4af7-875d-090ec7cfa263",
        "summary": "The photo depicts a clear day view of high-voltage electrical substation components, including disconnect switches and insulators.",
        "description": "This is a photograph of electrical substation infrastructure, showing high-voltage disconnect switches which are used to isolate electrical equipment for maintenance and safety. The cylindrical objects are insulators that prevent the flow of current to the ground and maintain the integrity of the electrical system.",
        "nameplate": false,
        "text": ""
      }
    ]
  }),
  methods: {
    showDialog(index) {
      this.selectedUseCase = this.usecases[index]
      this.useCaseDialog = true
    },
    showImage() {
      this.imageDialog = true
    },
  }
})
</script>
<style lang="scss">

</style>