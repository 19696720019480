<template>
  <v-container>
    <h1 class="mb-4">Google Chat Assistant (Pioneer)</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Background</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Proof of Concept ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Proof of Concept</h2>
            <v-row class="pa-2">
              <v-col v-for="usecase in usecases" :key="usecase.name" cols="3" xs="12" sm="6" md="3">
                <v-card class="mx-auto">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="usecase.image"
                    @click="showImageDialog(usecase.image)"
                  >
                  </v-img>
                  <v-divider></v-divider>
                  <v-card-title class="pb-0">
                    {{ usecase.name }}
                  </v-card-title>
                  <v-card-text class="text--primary">
                    {{ usecase.description }}
                  </v-card-text>
                  <v-card-actions>
                    <!-- <v-btn color="orange" text>Share</v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- ------------ Background ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Background</h2>
            <ul>
              <li>Google Chat has quickly become a staple of corporate communication</li>
              <li>Third-party apps can be added into Google Chat to enhance functionality</li>
              <li>Custom apps can be created that are private to the corporation</li>
              <li>Chat apps offer many great features such as slash commands, forms, cards, etc.</li>
              <li>A chat app can be used as a stand alone chat or referenced in existing chats</li>
            </ul>
            <v-alert type="info" class="mt-6">
              What if we had a custom app at Hydro Ottawa that could efficiently handle tasks within an interface we are already using daily
            </v-alert>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="imageDialog" width="800">
      <v-img
        :src="imageDialogImage"
        max-width="800"
      ></v-img>
    </v-dialog>
  </v-container>
</template>
<script>
export default ({
  name: 'PioneerExperiment',
  data: () => ({
    tab: 0,
    imageDialog: false,
    imageDialogImage: '',
    usecases: [
      {
        name: 'Personal Assistant',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-assistant.png?alt=media&token=64f1e5a8-9c99-4eb3-a76b-fd98176d1e49',
        description: 'Execute complex administrative tasks with natural language directly in a Google Chat window.',
        command: 'Set my out of office for next week',
      },  
      {
        name: 'General Enquiries',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-screenshot.png?alt=media&token=1ee51680-c273-4086-be38-7bd5d5823cdf',
        description: 'The bot can answer general questions including those specific to Hydro Ottawa and its services.',
        command: 'what is the voltage range for a HV 3 phase power line?'
      },
      {
        name: 'System Interface',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-outages-command.png?alt=media&token=ee126499-e7d3-471a-bd75-151aca301a1d',
        description: 'The bot offers custom commands that can be used to execute common tasks quickly and efficiently.',
        command: '/outages',
      },
      {
        name: 'Self-Service',
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Pioneer%2Fpioneer-self-serve.png?alt=media&token=690ad5ea-0471-41b1-9f72-5edfbad6258c',
        description: 'Interface with other self-service systems to create a seamless experience for employees',
        command: 'Submit a vacation request for next Friday'
      },
    ]
  }),
  methods: {
    showImageDialog(image) {
      this.imageDialogImage = image
      this.imageDialog = true
    }
  }
})
</script>
<style lang="scss">

</style>