<template>
  <v-container>
    <h1 class="mb-4">AI Image Generation</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Sandbox</v-tab>
        <v-tab>Background</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Proof of Concept ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Proof of Concept</h2>
            <v-row class="pa-2">
              <v-col v-for="usecase in usecases" :key="usecase.name" cols="3" xs="12" sm="6" md="3">
                <v-card class="mx-auto">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="usecase.image"
                    @click="showImageDialog(usecase.image)"
                  >
                  </v-img>
                  <v-divider></v-divider>
                  <v-card-text class="text--primary">
                    {{ usecase.description }}
                  </v-card-text>
                  <v-card-actions>
                    <!-- <v-btn color="orange" text>Share</v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- ------------ Sandbox ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Sandbox</h2>
            <p>In development.</p>
          </v-tab-item>
          <!-- ------------ Background ------------ -->
          <v-tab-item class="pa-6">
            <h2 class="mb-6">Background</h2>
            <ul>
              <li>OpenAI has developed a model called DALL·E that can generate images from textual descriptions</li>
              <li>It is a 12-billion parameter version of the GPT-3 language model</li>
              <li>It can generate images in a wide variety of styles and formats</li>
              <li>It can generate images from textual descriptions</li>
            </ul>
            <v-alert type="info" class="mt-6">
              Can we replace our marketing and design consultants with AI generated images?
            </v-alert>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="imageDialog" width="800">
      <v-img
        :src="imageDialogImage"
        max-width="800"
      ></v-img>
    </v-dialog>
  </v-container>
</template>
<script>
export default ({
  name: 'PioneerExperiment',
  data: () => ({
    tab: 0,
    imageDialog: false,
    imageDialogImage: '',
    usecases: [
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Imagen%2FDALL%C2%B7E%202024-02-08%2009.39.40%20-%20A%20scene%20on%20a%20beautiful%20summer%20day%20with%20a%20male%20power%20line%20maintainer%20for%20a%20utility%20company%2C%20fully%20inside%20the%20bucket%20of%20a%20bucket%20truck%2C%20wearing%20orange%20p.webp?alt=media&token=860dbb7a-7dde-43d6-9339-2eeae2188080',
        description: '',
      },  
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Imagen%2FDALL%C2%B7E%202024-02-08%2009.43.49%20-%20A%20scene%20on%20a%20cold%20winter%20day%20with%20a%20male%20power%20line%20maintainer%20for%20a%20utility%20company%2C%20fully%20inside%20the%20bucket%20of%20a%20bucket%20truck%2C%20wearing%20orange%20protec.webp?alt=media&token=62ebdaac-6cd9-4c2c-961b-66a4d5f40461',
        description: '',
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Imagen%2FDALL%C2%B7E%202024-02-08%2009.49.12%20-%20A%20close-up%20shot%20of%20the%20supervisor%20from%20a%20utility%20company%20on%20a%20cold%20winter%20day%2C%20smiling.%20He%20is%20dressed%20in%20winter%20work%20attire%2C%20including%20a%20warm%20hat%20and%20.webp?alt=media&token=ad776869-cbc3-42a9-b0e0-5548ffabb1fd',
        description: '',
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/Imagen%2FDALL%C2%B7E%202024-02-13%2023.53.32%20-%20Create%20an%20image%20in%20a%20Pixar-style%20animation%20of%20a%20happy%20couple%20sitting%20around%20a%20laptop%2C%20in%20a%20Scandinavian-style%20home%20interior.%20The%20home%20is%20characterized.webp?alt=media&token=a038ddf4-01f5-4f02-95d4-e01c73faca0a',
        description: '',
      },
    ]
  }),
  methods: {
    showImageDialog(image) {
      this.imageDialogImage = image
      this.imageDialog = true
    }
  }
})
</script>
<style lang="scss">

</style>