<template>
  <v-container>
    <h1 class="mb-4">Genesis Pilot</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Background</v-tab>
        <v-tab>Pipeline</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Objective ------------ -->
          <v-tab-item>
            Objective
          </v-tab-item>
          <!-- ------------ Demo ------------ -->
          <v-tab-item>
            Demo
          </v-tab-item>
          <!-- ------------ Pipeline ------------ -->
          <v-tab-item>
            Pipeline
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'GenesisPilot',
  data: () => ({
    tab: 0
  })
})
</script>
<style lang="scss">

</style>