import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";

// ------------ Customer Care ------------
import CustomerCareAgentAssistant from "../views/customercare/AgentAssistant.vue";
import CustomerCareArtificialAgent from "../views/customercare/ArtificialAgent.vue";
import CustomerCareCallAnalysis from "../views/customercare/CallAnalysis.vue";
import CustomerCareDailyReport from "../views/customercare/DailyReport.vue";

// ------------ Operations ------------
import OperationsSwitchOrders from "../views/operations/SwitchOrders.vue";

// ------------ Common ------------
import Blog from "../views/Blog.vue";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Team from "../views/Team.vue";

// ------------ Errors ------------
import Forbidden from "../views/errors/Forbidden.vue";
import NotFound from "../views/errors/NotFound.vue";
import Unknown from "../views/errors/Unknown.vue";

// ------------ Private ------------
import Dashboard from "../views/Dashboard.vue";

// ------------ Experiments ------------
import BillPredictionExperiment from "../views/experiments/BillPrediction.vue";
import DamageAssessmentExperiment from "../views/experiments/DamageAssessment.vue";
import ForestryExperiment from "../views/experiments/Forestry.vue";
import HydroOttawaLLMExperiment from "../views/experiments/HydroOttawaLLM.vue";
import ImagenExperiment from "../views/experiments/Imagen.vue";
import MusicExperiment from "../views/experiments/Music.vue";
import NameplateAutomationExperiment from "../views/experiments/NameplateAutomation.vue";
import PioneerExperiment from "../views/experiments/Pioneer.vue";
import RateApplicationExperiment from "../views/experiments/RateApplication.vue";
import WebsiteSearchExperiment from "../views/experiments/WebsiteSearch.vue";
import WorkforceExperiment from "../views/experiments/Workforce.vue";
import PodcastsExperiment from "../views/experiments/Podcasts.vue";

// ------------ Models ------------

// ------------ Pilots ------------
import CallClassifierPilot from "../views/pilots/CallClassifier.vue";
import DuetAIPilot from "../views/pilots/DuetAI.vue";
import GenesisPilot from "../views/pilots/Genesis.vue";
import RateAppPilot from "../views/pilots/RateApp.vue";

// ------------ Prototypes ------------

// ------------ Regulatory ------------
import RegulatoryRateApplicationAgent from "../views/regulatory/RateApplicationAgent.vue";

// ------------ Development ------------
import RateExplorerDevelopment from "../views/development/RateExplorer.vue";

// ------------ Releases ------------
import SmartSpeakerRelease from "../views/releases/SmartSpeakerRelease.vue";

Vue.use(VueRouter);

const routes = [
  // ------------ Common ------------
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      scope: "public",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      scope: "private",
      role: "Employee",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      scope: "private",
      role: "Employee",
    },
  },

  // ------------ Private ------------
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      scope: "private",
      role: "Employee",
    },
  },

  // ------------ Experiments ------------
  {
    path: "/experiment/bill-prediction",
    name: "BillPredictionExperiment",
    component: BillPredictionExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/damage-assessment",
    name: "DamageAssessmentExperiment",
    component: DamageAssessmentExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/forestry",
    name: "ForestryExperiment",
    component: ForestryExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/hydro-ottawa-llm",
    name: "HydroOttawaLLMExperiment",
    component: HydroOttawaLLMExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/imagen",
    name: "ImagenExperiment",
    component: ImagenExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/music",
    name: "MusicExperiment",
    component: MusicExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/nameplate-automation",
    name: "NameplateAutomationExperiment",
    component: NameplateAutomationExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/pioneer",
    name: "PioneerExperiment",
    component: PioneerExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/rate-application",
    name: "RateApplicationExperiment",
    component: RateApplicationExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/website-search",
    name: "WebsiteSearchExperiment",
    component: WebsiteSearchExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/workforce",
    name: "WorkforceExperiment",
    component: WorkforceExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },
  {
    path: "/experiment/podcasts",
    name: "PodcastsExperiment",
    component: PodcastsExperiment,
    meta: {
      scope: "private",
      template: "experiment",
      role: "Innovator",
    },
  },

  // ------------ Pilots ------------
  {
    path: "/pilot/call-classifier",
    name: "CallClassifierPilot",
    component: CallClassifierPilot,
    meta: {
      scope: "private",
      role: "Pioneer",
    },
  },
  {
    path: "/pilot/duet",
    name: "DuetAIPilot",
    component: DuetAIPilot,
    meta: {
      scope: "private",
      role: "Pioneer",
    },
  },
  {
    path: "/pilot/genesis",
    name: "GenesisPilot",
    component: GenesisPilot,
    meta: {
      scope: "private",
      role: "Pioneer",
    },
  },
  {
    path: "/pilot/rateapp",
    name: "RateAppPilot",
    component: RateAppPilot,
    meta: {
      scope: "private",
      role: "Pioneer",
    },
  },

  // ------------ Customer Care ------------
  {
    path: "/customer-care/agent-assistant",
    name: "CustomerCareAgentAssistant",
    component: CustomerCareAgentAssistant,
    meta: {
      scope: "private",
      role: "Customer Care",
    },
  },
  {
    path: "/customer-care/artificial-agent",
    name: "CustomerCareArtificialAgent",
    component: CustomerCareArtificialAgent,
    meta: {
      scope: "private",
      role: "Pioneer",
    },
  },
  {
    path: "/customer-care/call-analysis",
    name: "CustomerCareCallAnalysis",
    component: CustomerCareCallAnalysis,
    meta: {
      scope: "private",
      role: "Customer Care",
    },
  },
  {
    path: "/customer-care/daily-report",
    name: "CustomerCareDailyReport",
    component: CustomerCareDailyReport,
    meta: {
      scope: "private",
      role: "Customer Care",
    },
  },

  // ------------ Regulatory ------------
  {
    path: "/regulatory/rate-application-agent",
    name: "RegulatoryRateApplicationAgent",
    component: RegulatoryRateApplicationAgent,
    meta: {
      scope: "private",
      role: "Regulatory",
    },
  },

  // ------------ Operations ------------
  {
    path: "/operations/switch-orders",
    name: "OperationsSwitchOrders",
    component: OperationsSwitchOrders,
    meta: {
      scope: "private",
      role: "Operations",
    },
  },

  // ------------ Development ------------
  {
    path: "/development/explorer",
    name: "RateExplorerDevelopment",
    component: RateExplorerDevelopment,
    meta: {
      scope: "private",
    },
  },

  // ------------ Releases ------------
  {
    path: "/releases/smart-speaker",
    name: "SmartSpeakerRelease",
    component: SmartSpeakerRelease,
    meta: {
      scope: "private",
    },
  },

  // ------------ Errors ------------
  {
    path: "/403",
    name: "Forbidden",
    component: Forbidden,
    meta: {
      scope: "public",
    },
  },
  {
    path: "/520",
    name: "Unknown",
    component: Unknown,
    meta: {
      scope: "public",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      scope: "public",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  next();
  // ---------------------- Public ----------------------
  if (to.meta.scope == "public") {
    next();
  } else {
    let user = getAuth().currentUser;
    // ---------------------- Not Public ----------------------
    if (user) {
      // ---------------------- Logged In ----------------------
      // We need to check if the user has permissions to access the route
      // Get the user document
      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      getDoc(userRef)
        .then((doc) => {
          if (doc.exists()) {
            // ----------- User exists -----------
            // Check if the user has the required permissions.
            // The user field roles has an array of roles. We need to ensure the role defined on each route matches the array.
            // or if the user is an admin
            let userRoles = doc.data().roles;
            if (to.meta.role) {
              if (userRoles.includes("Admin")) {
                // User is an admin
                next();
              } else if (userRoles.includes(to.meta.role)) {
                // User has the required role
                next();
              } else {
                // User does not have the required role
                next({ name: "Forbidden" });
              }
            } else {
              // Route does not have a role defined
              next();
            }
          } else {
            // User does not exist
            next({ name: "Landing" });
          }
        })
        .catch((error) => {
          // Error getting user document
          console.error("Error getting user document:", error);
          next({ name: "Unknown" });
        });
    } else {
      // ---------------------- Not Logged In ----------------------
      next({ name: "Landing" });
    }
  }
});

export default router;
