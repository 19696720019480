<template>
  <v-container>
    <h1 class="mb-4">Smart Speaker Apps</h1>
    <v-card>
      <v-card-title>Obective</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        Coming soon...
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'SmartSpeakerRelease',
  return: {

  }
})
</script>
<style lang="scss">

</style>