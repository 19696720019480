<template>
  <div class="d-flex flex-column">
    <div class="header" v-if="route != 'Landing' && user">
      <v-container>
        <div class="d-flex flex-row align-center">
          <!-- <img src="@/assets/skunkworks+border.png" height="40px" width="40px" contain> -->
          <v-btn icon color="black"><v-icon>mdi-lightning-bolt</v-icon></v-btn>
          <div class="overline ml-2">
            AI Skunkworks
          </div>
          <v-spacer></v-spacer>
          <div v-if="user" class="d-flex flex-row align-center">
            {{ user.displayName }}
            <v-btn icon @click="logout()" class="ml-2"><v-icon small>mdi-logout</v-icon></v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <div v-if="user && hasRole('Employee')" class="subheader">
      <v-container class="py-0">
        <v-tabs background-color="primary" dark>
          <v-tab @click="go('Dashboard')">
            Dashboard
          </v-tab>
          <v-menu v-if="hasRole('Innovator')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Experiments
              </v-tab>
            </template>
            <v-list>
              <div v-for="(item,index) in experiments" :key="'experiment' + index" link @click="go(item.route)">
                <v-divider v-if="item.type === 'divider'" class="my-2"></v-divider>
                <v-subheader v-else-if="item.type === 'subheader'" class="font-weight-bold">{{ item.title }}</v-subheader>
                <v-list-item v-else link>
                  <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-menu v-if="hasRole('Pioneer')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Pilots
              </v-tab>
            </template>
            <v-list>
              <div v-for="item in pilots" :key="item.route" link @click="go(item.route)">
                <v-divider v-if="item.title === 'Divider'" class="my-2"></v-divider>
                <v-list-item v-else link>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <!-- <v-menu v-if="hasRole('Tester')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Development
              </v-tab>
            </template>
            <v-list>
              <div v-for="item in development" :key="item.route" link @click="go(item.route)">
                <v-divider v-if="item.title === 'Divider'" class="my-2"></v-divider>
                <v-list-item v-else link>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu> -->
          <v-menu v-if="hasRole('Customer Care')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Customer Care
              </v-tab>
            </template>
            <v-list>
              <div v-for="(item,index) in customercare" :key="'customer-care-' + index" link @click="go(item.route)">
                <v-divider v-if="item.type === 'divider'" class="my-2"></v-divider>
                <v-subheader v-else-if="item.type === 'subheader'" class="font-weight-bold">{{ item.title }}</v-subheader>
                <v-list-item v-else link>
                  <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-menu v-if="hasRole('Regulatory')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Regulatory
              </v-tab>
            </template>
            <v-list>
              <div v-for="(item,index) in regulatory" :key="'regulatory' + index" link @click="go(item.route)">
                <v-divider v-if="item.type === 'divider'" class="my-2"></v-divider>
                <v-subheader v-else-if="item.type === 'subheader'" class="font-weight-bold">{{ item.title }}</v-subheader>
                <v-list-item v-else link>
                  <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-menu v-if="hasRole('Operations')" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Operations
              </v-tab>
            </template>
            <v-list>
              <div v-for="(item,index) in operations" :key="'operations' + index" link @click="go(item.route)">
                <v-divider v-if="item.type === 'divider'" class="my-2"></v-divider>
                <v-subheader v-else-if="item.type === 'subheader'" class="font-weight-bold">{{ item.title }}</v-subheader>
                <v-list-item v-else link>
                  <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <!-- <v-tab @click="go('Blog')">Blog</v-tab> -->
          <!-- <v-tab @click="go('Team')">Team</v-tab> -->
        </v-tabs>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['user'],
  data: () => ({
    route: null,
    experiments: [
      { title: 'Visual Models', type: 'subheader' },
      { title: 'Nameplate Automation', route: 'NameplateAutomationExperiment' },
      { title: 'Damage Assessment', route: 'DamageAssessmentExperiment' },
      // { title: 'Forestry Identification', route: 'ForestryExperiment' },
      { title: 'Imagen', route: 'ImagenExperiment' },
      { type: 'divider' },

      { title: 'Language Models', type: 'subheader' },
      { title: 'Website Search', route: 'WebsiteSearchExperiment' },
      { title: 'Hydro Ottawa LLM', route: 'NameplateAutomationExperiment' },
      { title: 'AI Workforce', route: 'WorkforceExperiment' },
      { type: 'divider' },

      { title: 'Predictive Models', type: 'subheader' },
      { title: 'Bill Prediction', route: 'BillPredictionExperiment' },
      { type: 'divider' },
      
      { title: 'Miscellaneous', type: 'subheader' },
      { title: 'Google Chat Assistant', route: 'PioneerExperiment' },
      { title: 'Podcast Demo', route: 'PodcastsExperiment' },
      { title: 'Music Demo', route: 'MusicExperiment' },
    ],
    pilots: [
      { title: 'Google Gemini', route: 'DuetAIPilot' },
      { title: 'Call Classifier', route: 'CallClassifierPilot' },
      { title: 'Rate Application', route: 'RegulatoryRateApplicationAgent' },
    ],
    // models: [
      // { title: 'Asset Data', route: 'AssetModel' },
      // { title: 'Outage Data', route: 'OutageModel' },
      // { title: 'Customer Data', route: 'CustomerModel' },
      // { title: 'Consumption Data', route: 'ConsumptionModel' },
      // { title: 'Grid Data', route: 'GridModel' },
    // ],
    // development: [
    //   { title: 'Rate Explorer', route: 'RateExplorerDevelopment' },
    // ],
    releases: [
      { title: 'Smart Speaker', route: 'SmartSpeakerRelease' },
    ],
    customercare: [
      { title: 'Agent Assistant', route: 'CustomerCareAgentAssistant' },
      { title: 'Artificial Agent', route: 'CustomerCareArtificialAgent' },
      { title: 'Call Analysis', route: 'CustomerCareCallAnalysis' },
      { title: 'Daily Report', route: 'CustomerCareDailyReport' },
    ],
    regulatory: [
      { title: 'Rate Application', route: 'RegulatoryRateApplicationAgent' },
    ],
    operations: [
      { title: 'Switch Orders', route: 'OperationsSwitchOrders' },
      { title: 'Nameplate Experiment', route: 'NameplateAutomationExperiment' },
    ],
  }),
  created() {
    this.route = this.$route.name
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    hasRole(role) {
      // console.log('header', role, this.user.roles, this.user.roles.includes(role))
      return this.user && this.user.roles && this.user.roles.length && (this.user.roles.includes(role) || this.user.roles.includes('Admin'))
    },
  },
  watch: {
    $route(to, from) {
      this.route = to.name
    },
  }
}
</script>
<style lang="scss">
.header {
  background: white;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
}
.subheader {
  background: black;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
}
</style>