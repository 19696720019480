<template>
  <v-container>
    <h1 class="mb-4">Bill Prediction</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Background</v-tab>
        <v-tab>Analysis</v-tab>
        <v-tab>Proof of Concept</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">

          <!-- ------------ Background ------------ -->
          <v-tab-item class="pa-6">
            <Background :background="model.background"></Background>
          </v-tab-item>
          
          <!-- ------------ Analysis ------------ -->
          <v-tab-item class="pa-6">
            <Analysis :analysis="model.analysis"></Analysis>
          </v-tab-item>

          <!-- ------------ Proof of Concept ------------ -->
          <v-tab-item class="pa-6">
            <Concepts :concept="model.concept"></Concepts>
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Background from '@/components/Background.vue'
import Analysis from '@/components/Analysis.vue'
import Concept from '@/components/Concept.vue'
export default ({
  name: 'BillPrediction',
  components: {
    Background, Analysis, Concept
  },
  data: () => ({
    tab: 0,
    model: {
      background: {
        idea: [
          {
            colour: 'grey lighten-4',
            type: 'title',
            content: 'Can we extract useful insights from our historical call recordings?'
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Origin"
          },
          {
            colour: 'white',
            type: 'text',
            content: "Hydro Ottawa maintains a large repository of call recordings from customer interactions. Over 6 years, we have recorded 2,159,738 calls. If we could feed this data into a large language model then we could extract useful insights from the data that would otherwise be cost prohibitive to obtain."
          },
        ],
        cases: [
          {
            colour: 'grey lighten-4',
            type: 'list',
            content: [
              'Topic Categorization',
              'Understanding Sentiment',
              'Issue Identification, Resolution, and Recommendations',
              'Agent Performance and Training',
              'Operational Efficiency & Self-serve',
              'Predictive Analysis'
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Topic Categorization",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Automatically classifying calls into predefined topics or categories.",
              "Identifying recurring themes or subjects in conversations.",
              "Facilitating targeted analysis and reporting based on call topics (including productivity/efficiency measures like average handling time)",
              "Enabling sharpened understanding of CSAT based on customer intent (categorization) to truly understand",
              "Identify opportunities to optimize call routing (IVR)",
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Topic Categorization (today)",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "agent categorizes based on a pick list - issue, too broad, misclassification, no entries",
              "For example: Billing is the top category ringing in at a whopping 37% of all the calls - question is - what about billing is the inquiry about - can we isolate a true “High bill” inquiry from one that simply indicates the customer doesnt understand their bill, or its that the usage is not high, but rather they didnt pay last month's bill, or their OESP support stopped, etc..",
              "Another example : could have multiple topics in call journey - customer called to move (thats one intent), he has to choose a rate option that requires an explanation (thats another intent - rate option selection), etc.  If we could identify and summarize the different categories/calls we could then pick out , say “rate option” and focus on those to understand whether we have effective agent articulation through a customers lens, and/or whether relevant online tools were promoted all to help inform customer.  We could develop workshops/trainig depending on the topic.",
              "We could also expand the topics to monitor as relevant, configured, dynamic with no impact or effort on agent's part",
              "New topics could be recommended by the AI - especially around new trends; inquiries etc"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Understanding Sentiment",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Detecting customer emotions (e.g., frustration, satisfaction).",
              "Assessing the tone and mood of both the customer and the agent.",
              "Identifying shifts in sentiment during the call.",
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Understanding Sentiment Benefits",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Enhanced Training and Coaching: Use insights from sentiment analysis to inform agent training and coaching. Focus on areas like empathy, conflict resolution, and customer engagement.; Develop targeted training programs to improve emotional intelligence and customer handling skills of the agents. Evaluate agent performance in handling different emotional contexts and use this data to drive improvement at agent level",
              "Improved Customer Experience and Satisfaction:Understand the factors leading to positive or negative sentiments, allowing us to refine programs, services, and customer interactions; Implement changes that proactively address common customer pain points",
              "Feedback and Performance Analysis:Analyze sentiment trends over time to assess the impact of policy changes, new products, or marketing campaigns on customer sentiment; Evaluate agent performance in handling different emotional contexts and use this data in performance reviews and recognition programs.",
              "Crisis Management and Brand Reputation: identify and address widespread issues or negative sentiment trends that could lead to a public relations crisis.; Protect and enhance brand reputation by demonstrating responsiveness and a customer-centric approach."
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Issue Identification, Resolution, and Recommendations",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Detecting specific issues or problems mentioned in the conversation.",
              "Analyzing common issues across calls to improve product or service.",
              "Suggesting potential solutions or responses to the agent in real-time.(eventually)",
              "Recommending promotional opportunities where appropriate.",
              "Tailoring interactions based on customer history and preferences.",
              "Predicting and preempting potential customer issues."
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Agent Performance and Training",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Analyzing agent performance for training and development purposes.",
              "Identifying best practices and areas for improvement.",
              "Customizing training programs based on individual agent needs."
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Operational Efficiency & Self-serve",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Understand reasons for repeat calls",
              "First call resolution",
              "self-serve opportunities"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Predictive Analytics",
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Anticipating future trends and customer needs based on call data.",
              "Informing strategic decisions through comprehensive analysis.",
              "Identifying opportunities for innovation in products and services."
            ]
          }
        ],
        goals: [
          {
            colour: 'grey lighten-4',
            type: 'list',
            content: [
              "Natural Search",
              "Conversational Search",
              "Dashboard",
              "Call Classification",
              "Recommendations",
              "Journeys",
              "Trends"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Natural Search"
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Query the entire dataset with natural language queries",
              "ex. how many records mention automatic payments",
              "ex. return records that mention call quality"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Conversational Search"
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Users may directly converse with the data itself",
              "Restricted to the token limit of the model",
              "Provides an interactive chat interface"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Dashboard"
          },
          {
            colour: 'white',
            type: 'text',
            content: "Simple graphs and lists which summarize pre-calculated data such as agent sentiment"
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Call Classification"
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Classifies based on recommended categories (multiple, weighted)",
              "Classify based on the known categories: Billing, Moves, Not Coded by Agent, Acct Maintenance, Collections, Payment, MyAccount, Operations, Other, Reconnections, Pre-Authorized Payment Plan, Social Assist, Forestry, LLR, Outages, Booking, Battery Loan Inquiry, Rate Option, Document Request, Surveys, MOBILE APP, Layouts, Outage Alert Inq, Scam, Usage Inq, CEAPSB, Next Level Complaint, Deposit Inquiry, Complaint & Escalations, Cancelling, EV Inquiry, LD, PROMOTION, IVR Self-Service, Usage Online, CEAP, Home Energy Reports, CDM, Green Button, Supervisions",
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Recommendations"
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Users may directly converse with the data itself",
              "Restricted to the token limit of the model",
              "Provides an interactive chat interface"
            ]
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Journeys"
          },
          {
            colour: 'white',
            type: 'text',
            content: "Attempt to extract customer jouneys. We start by identifying call resolutions and link them to call entries."
          },
          {
            colour: 'white',
            type: 'heading',
            content: "Trends"
          },
          {
            colour: 'white',
            type: 'text',
            content: "Provide graphs for pre-defined trends topics. For example, we could provide a graph of the number of calls that mention 'carbon tax' over time. Ultimately, trends will be used to power an alerting system."
          }
        ],
        approach: [
          {
            colour: 'grey lighten-4',
            type: 'title',
            content: 'Speech-to-text, Natural Language Processing, Machine Learning'
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Obtain sample audio files",
              "Analyze speech-to-text models",
              "Analyze LLM models",
              "Process sample files",
              "Share results",
              "Gain access to full data set",
              "Develop pipeline",
              "Process 1 year of data",
              "Create interactive app",
            ]
          }
        ],
        status: [
          {
            colour: 'grey lighten-4',
            type: 'title',
            content: 'Gain access to full data set'
          },
          {
            colour: 'white',
            type: 'list',
            content: [
              "Obtained sample of audio files",
              "Analyze speech-to-text models",
              "Analyzed LLM models",
              "Process sample files",
              "Shared results",
              "Develop pipeline",
              "Gain access to full data set",
              "Present to EMT",
              "Process 1 year of data",
              "Create interactive app",
            ]
          }

        ],
        conclusion: [
          {
            colour: 'yellow lighten-4',
            type: 'title',
            content: 'In Development'
          },
        ]
      },
      analysis: {
        panels: [
          
        ]
      },
      concepts: []
    }
  })
})
</script>
<style lang="scss">

</style>