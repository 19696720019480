<template>
  <v-container>
    <h1 class="mb-4">Daily Report</h1>
    <v-card>
      <v-card-title>Daily Report</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert type="info">
          Once the live data feed is established, this tool will generate an interactive daily report of the previous days calls.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'DailyReport',
  data: () => ({
    
  }),
  methods: {
    
  },
  filters: {
    
  },
  watch: {
    user() {
      
    },
    $route(to, from) {
      
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
