<template>
  <v-container>
    <h1 class="mb-4">Web Search</h1>
    <v-card>
      <v-card-title>Intelligent Search</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <WebSearch></WebSearch>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import WebSearch from '@/components/WebSearch.vue';
export default ({
  name: 'WebsiteSearchExperiment',
  props: ['user'],
  components: {
    WebSearch,
  },
  data: () => ({

  }), 
  created() {
    
  }
})
</script>
<style lang="scss">

</style>