<template>
  <v-container>
    <h1 class="mb-4">Call Analysis</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Assistant</v-tab>
        <v-tab>Explorer</v-tab>
        <v-tab>Categories</v-tab>
        <v-tab>Journeys</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-tabs-items v-model="tab">
          <!-- ------------ Data Assistant ------------ -->
          <v-tab-item class="pa-6">
            <v-card-text>
              <v-alert outlined type="info">
                <strong>Version 0.2</strong><br>
                <ul>
                  <li>The dataset is limited to the last 800 calls at the time of the snapshot.</li>
                  <li>Call context for more accurate responses</li>
                  <li>Linked call IDs</li>
                </ul>
              </v-alert>
              <v-text-field v-model="userQuery" filled rounded @keyup.enter="sendQuery" append-outer-icon="mdi-send" @click:append-outer="sendQuery" :disabled="assistantLoading">
                <template v-slot:prependInner>
                  <v-icon>mdi-check</v-icon>
                </template>
                <template v-slot:append>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item v-for="(prompt, index) in prompts" :key="'prompt' + index" @click="userQuery = prompt.title">
                        <v-list-item-subtitle class="ellipsis text-left">{{ prompt.title | truncate }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-text-field>
              <v-divider></v-divider>
              <div class="message-history">
                <div v-if="assistantLoading" class="text-center pa-4">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  <span class="ml-2">{{ timer }} seconds (processing will take a few minute)</span>
                </div>
                <v-slide-x-transition group class="d-flex flex-column-reverse">
                  <div v-for="(message, index) in history" :key="index" class="message d-flex" :class="message.type == 'user' ? 'justify-end' : 'justify-start'">
                    <div v-if="message.type === 'user'" class="user-message pa-4 ma-2">
                      {{ message.content }}
                    </div>
                    <div v-else>
                      <div>
                        <div class="system-message pa-6 ma-2" v-html="message.content"></div>
                        <div v-if="message.citations && message.citations.length" class="citations ml-2">
                          <div v-for="(citation, citationIndex) in message.citations" :key="'citation-' + index + '-' + citationIndex" class="text-caption">
                            [{{ citationIndex + 1 }}] <a :href="citation.uri" target="_blank">{{ citation.uri }}</a> ({{ citation.startIndex }} - {{ citation.endIndex }})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-slide-x-transition>
              </div>
              <div class="d-flex justify-end">
                <v-btn v-if="history.length" @click="startConversation()" :disabled="assistantLoading" text><v-icon left>mdi-refresh</v-icon> Reset Conversation</v-btn>
              </div>
            </v-card-text>
          </v-tab-item>
          <!-- ------------ Data Explorer ------------ -->
          <v-tab-item class="pa-6">
            <v-alert outlined type="info">
              <strong>Version 0.2</strong><br>
              <ul>
                <li>Slower initial load but full dataset</li>
                <li>Search button for less lag</li>
                <li>More detailed descriptions</li>
                <li>OpenAI transcriptions</li>
              </ul>
            </v-alert>
            <v-card-text v-if="explorerLoadingState == 0" class="my-3">
              <v-btn @click="loadExplorerData" color="primary">Load Data</v-btn>
            </v-card-text>
            <v-card-text v-else-if="explorerLoadingState == 1" class="my-3">
              Loading 162,728 records (about 20 seconds)...
              <div class="mt-3">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                append-outer-icon="mdi-send"
                @click:append-outer="logQuery('explorer'); executeSearch()"
                label="Search"
                single-line
                hide-details
                filled
                class="mb-6"
                rounded
                @blur="logQuery('explorer'); executeSearch()"
                @keyup.enter="logQuery('explorer'); executeSearch()"
              ></v-text-field>
              <v-data-table
                :headers="headers"
                :items="filteredItems"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="elevation-1"
                @page-count="pageCount = $event"
                @click:row="openCallDialog"
              ></v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
            </v-card-text>
          </v-tab-item>
          <!-- ------------ Data Categories ------------ -->
          <v-tab-item class="pa-6">
            <v-alert outlined type="info">
              <strong>Version 0.2</strong><br>
              <ul>
                <li>CC&B Categories</li>
                <li>Limited to maximum of 100 example calls of each</li>
                <li>Data has not been sanitized</li>
              </ul>
            </v-alert>
            <v-card-text v-if="categoriesLoadingState == 0" class="my-3">
              <v-btn @click="loadCategoryData" color="primary">Load Data</v-btn>
            </v-card-text>
            <v-card-text v-else-if="categoriesLoadingState == 1" class="my-3">
              <div class="mt-3">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-expansion-panels>
                <v-expansion-panel v-for="(category, index) in categories" :key="'category'+index">
                  <v-expansion-panel-header>
                    <div class="text-h6">{{ category.name }} ({{ category.count }})</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(call, index2) in category.calls" :key="'link-' + index + '-' + index2">
                      <a href="#" @click="openCallDialog({ id: call })">{{ call }}</a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-tab-item>
          <!-- ------------ Data Journeys ------------ -->
          <v-tab-item class="pa-6">
            <v-alert outlined type="info">
              <strong>Version 0.2</strong><br>
              <ul>
                <li>Many records to load (takes several minutes and free memory)</li>
                <li>Highly gradular (requires pruning)</li>
              </ul>
            </v-alert>
            <v-card-text v-if="journeysLoadingState == 0">
              <v-btn @click="loadJourneyData" color="primary">Load Journeys</v-btn>
            </v-card-text>
            <v-card-text v-else-if="journeysLoadingState == 1">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-text>
            <v-card-text v-else>
              <v-expansion-panels>
                <v-expansion-panel v-for="(journey, index) in journeys" :key="'journey'+index">
                  <v-expansion-panel-header>
                    <div class="text-h6">{{ journey.name }} ({{ journey.count }})</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(call, index2) in journey.calls" :key="'link-journey-' + index + '-' + index2">
                      <a href="#" @click="openCallDialog({ id: call })">{{ call }}</a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="callDialog" width="800">
      <v-card class="text-left">
        <v-card-title>
          {{ selectedItem?.id }}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="callDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-sheet color="#f1f3f4" class="d-flex align-center">
          <audio ref="transcriptPlayer" :src="'https://storage.googleapis.com/hydroottawa-audio/converted/converted_' + selectedItem?.id + '.wav'" controls></audio>
        </v-sheet>
        <v-card-text class="mt-6">
          <v-row>
            <v-col cols="12" md="6">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Summary</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.reasoning?.gemini?.Summary?.detailed }}</v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Transcription (Google)</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.transcription?.chirp }}</v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Transcription (OpenAI)</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.transcription?.whisper }}</v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="6">
              <v-expansion-panels>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Categories</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>CC&B Category:</strong> {{ selectedItem?.reasoning?.gemini?.Categorization?.category }}<br>
                    <strong>CC&B Category Reasoning:</strong> {{ selectedItem?.reasoning?.gemini?.Categorization?.reasoning }}<br>
                    <hr>
                    <strong>Primary:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.primary_issue }}<br>
                    <strong>Secondary:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.secondary_issues | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Call Information</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Date:</strong> {{ selectedItem?.date }}<br>
                    <strong>Quality:</strong> {{ selectedItem?.reasoning?.gemini?.['Call Information']?.call_quality }}<br>
                    <strong>Customer:</strong> {{ selectedItem?.reasoning?.gemini?.['Call Information']?.caller_name }}<br>
                    <strong>Address:</strong> {{ selectedItem?.reasoning?.gemini?.['Call Information']?.caller_address }}<br>
                    <strong>Agent:</strong> {{ selectedItem?.reasoning?.gemini?.['Call Information']?.agent_name }}<br>
                    <strong>Language:</strong> {{ selectedItem?.reasoning?.gemini?.['Language']?.language }}<br>
                    <strong>Curses:</strong> {{ selectedItem?.reasoning?.gemini?.['Language']?.curses }}<br>
                    <strong>Hold Occurrences:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.hold_occurrences }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Issue Identification</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Customer Concerns:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.issue_identification?.customer_concerns }}<br>
                    <strong>Specific Concerns:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.issue_identification?.specific_issues | joinArray }}<br>
                    <strong>Agreed Solution:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.agreed_solution }}<br>
                    <strong>Confirmation Number:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.confirmation_number }}<br>
                    <strong>Recommendations:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.recommendations | joinArray }}<br>
                    <strong>Follow-up:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.follow_up }}<br>
                    <strong>Next Steps:</strong> {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.next_steps | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">MyAccount</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Expired Password?:</strong> {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.expired_password }}<br>
                    <strong>Issues:</strong> {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.myaccount_issues | joinArray }}<br>
                    <strong>Tried MyAccount:</strong> {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.tried_myaccount }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Sentiment</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Agent:</strong> {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.agent }}<br>
                    <strong>Customer:</strong> {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.customer }}<br>
                    <strong>Overall:</strong> {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.overall }}<br>
                    <strong>Score:</strong> {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.score }}<br>
                    <strong>Shifts?:</strong> {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.shifts | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Policy Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Knowledge:</strong> {{ selectedItem?.reasoning?.gemini?.['AI Training']?.valuable_information | joinArray }}<br>
                    <strong>Policies:</strong> {{ selectedItem?.reasoning?.gemini?.['AI Training']?.policies | joinArray }}<br>
                    <strong>Procedures:</strong> {{ selectedItem?.reasoning?.gemini?.['AI Training']?.procedures | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Triage Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Customer:</strong> {{ selectedItem?.reasoning?.gemini?.['Triage']?.first_customer_statement }}<br>
                    <strong>Human Response:</strong> {{ selectedItem?.reasoning?.gemini?.['Triage']?.first_agent_response }}<br>
                    <strong>AI Response:</strong> {{ selectedItem?.reasoning?.gemini?.['Triage']?.ai_response }}<br>
                    <strong>Scenario:</strong> {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.training?.scenario }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Key Actions</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Programs & Services Discussed:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.programs_and_services_discussed | joinArray }}<br>
                    <strong>Agent Guidance:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.agent_guidance | joinArray }}<br>
                    <strong>Documentation:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.documentation_needs }}<br>
                    <strong>Payment:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.payment_information }}<br>
                    <strong>Modifications:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.cancellation_modification_actions | joinArray }}<br>
                    <strong>Payment Plan Changed:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.payment_plan_setup }}<br>
                    <strong>System References:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.system_references | joinArray }}<br>
                    <strong>Verification Process:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.verification_process | joinArray }}<br>
                    <strong>Agent Follow-up Confirmation:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.agent_followup_confirmation }}<br>
                    <strong>Customer Requests:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.customer_requests | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Agent Performance and Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Best Practices:</strong> {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.best_practices | joinArray }}<br>
                    <strong>Improvement Areas:</strong> {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.improvement_areas | joinArray }}<br>
                    <strong>Best Practices:</strong> {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.training?.scenario }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Improvements</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>System Improvements:</strong> {{ improvements(selectedItem?.reasoning?.gemini?.['Improvements']?.system_improvements) }}<br>
                    <strong>Service Improvements:</strong> {{ improvements(selectedItem?.reasoning?.gemini?.['Improvements']?.service_improvements) }}<br>
                    <!-- <strong>Call Prevention:</strong> {{ selectedItem?.reasoning?.gemini?.['Improvements']?.system_improvements | joinArray }}<br> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFirestore, collection, getDoc, collectionGroup, getDocs, query, limit, startAfter, orderBy, addDoc, setDoc, doc } from 'firebase/firestore'

export default ({
  name: 'CallAnalysis',
  props: ['user'],
  data: () => ({
    // General
    tab: 0,
    // Data Explorer
    explorerLoading: false,
    progress: 0,
    items: [],
    filteredItems: [],
    itemsPerPage: 10,
    page: 1,
    pageCount: 0,
    processed: 0,
    matches: 0,
    search: '',
    headers: [
      { text: 'Call ID', value: 'id' },
      { text: 'Date', value: 'date' },
      { text: 'Category', value: 'category' },
      // { text: 'Sentiment', value: 'sentiment' },
      { text: 'Length', value: 'length' },
      { text: 'Summary', value: 'summary' },
    ],
    panel: 0,
    //
    callDialog: false,
    selectedItem: null,
    stopLoadingFlag: false,
    lastVisible: null,  // Add a variable to store the last visible document
    timer: 0, // Timer for loading processes
    timerInterval: null, // Interval for the timer
    // Assistant
    assistantLoading: false,
    userQuery: '',
    sessionId: Date.now().toString(36) + Math.random().toString(36), // Generate a simple unique session ID
    prompts: [
      { title: 'Analyze the call quality', class: 'primary' },
      { title: 'How can Hydro Ottawa improve', class: 'primary' },
      { title: 'what are customers calling about the most, list one thing', class: 'primary' },
      { title: 'can you breakdown the billing issues into 5 sub categories', class: 'primary' },
      { title: 'were there any calls that stood out as being a non-standard category', class: 'primary' },
      { title: 'tell me more about the power quality issues', class: 'primary' },
      { title: 'draft an email explaining that to a customer that has complained about this', class: 'primary' },
    ],
    history: [],
    explorerLogDocId: null, // Separate logDocId for Explorer
    assistantLogDocId: null, // Separate logDocId for Assistant

    // Categories
    categories: [],
    // Journeys
    journeys: [],
    // Loading States
    explorerLoadingState: 0,
    journeysLoadingState: 0,
    categoriesLoadingState: 0,
  }),
  filters: {
    joinArray(value) {
      return Array.isArray(value) ? value.join(', ') : 'N/A';
    }
  },
  methods: {
    async loadExplorerData() {
      this.explorerLoadingState = 1;
      await this.load(true); // Initial load only, fetching the first 500 records
      this.filteredItems = this.items;
      this.explorerLogDocId = null; // Reset explorer log document ID for a new search
      this.explorerLoadingState = 2;
    },
    async loadCategoryData() {
      this.categoriesLoadingState = 1;
      await this.loadCategories();
      this.categoriesLoadingState = 2;
    },
    async loadJourneyData() {
      this.journeysLoadingState = 1;
      await this.loadJourneys();
      this.journeysLoadingState = 2;
    },
    async load(initialLoad = false) {
      console.log('Loading initiated...');
      this.explorerLoading = true;
      this.startTimer();
      this.explorerLoadingState = 1;
      try {
        // Fetch the signed URL from the Cloud Function
        const urlResponse = await fetch('https://northamerica-northeast1-hydro-ottawa-ai.cloudfunctions.net/cs_get_calls');
        if (!urlResponse.ok) {
          throw new Error('Failed to fetch signed URL');
        }
        const urlData = await urlResponse.json();

        // Use Fetch API with a ReadableStream to monitor progress
        const response = await fetch(urlData.url);
        if (!response.ok) {
          throw new Error('Failed to fetch call data');
        }

        const contentLength = parseInt(response.headers.get('content-length'), 10);
        const totalBytes = 58787728;  // Set the total bytes manually since you know it
        let loadedBytes = 0;
        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start: (controller) => {
            const push = () => {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                loadedBytes += value.length;
                const progress = (loadedBytes / totalBytes) * 100;
                // console.log(`Progress: ${progress.toFixed(2)}%`);
                // this.progress = progress;  // Update the progress bar
                controller.enqueue(value);
                push();
              });
            };
            push();
          }
        });

        const newResponse = new Response(stream);
        const data = await newResponse.json();

        // Assuming the data is an array of call records
        this.items = data;
        this.processed = data.length;

        this.explorerLoading = false;
        this.stopTimer();
        this.executeSearch();

        if (initialLoad) {
          return;
        }

        if (!this.stopLoadingFlag && data.length > 0) {
          this.load();
        }
        
      } catch (error) {
        console.error('Error loading call data:', error);
        this.explorerLoading = false;
        this.stopTimer();
      }
    },
    executeSearch() {
      console.log('search')
      this.filteredItems = this.items.filter(item => {
        const searchTerm = this.search.toLowerCase();
        if (!searchTerm) return true;
        return (typeof item.id === 'string' && item.id.toLowerCase().includes(searchTerm) || 
                typeof item.date === 'string' && item.date.toLowerCase().includes(searchTerm) || 
                typeof item.category === 'string' && item.category.toLowerCase().includes(searchTerm) || 
                typeof item.summary === 'string' && item.summary.toLowerCase().includes(searchTerm));
      });
    },
    startContinuousLoading() {
      this.stopLoadingFlag = false;
      this.load();
    },
    stopLoading() {
      this.stopLoadingFlag = true;
      this.explorerLoading = false;
      this.stopTimer();
    },
    async openCallDialog(item) {
      console.log(item.id)
      if (!item) return false
      // Fetch the selected item from the Firebase collection
      const db = getFirestore();
      const callsRef = collection(db, 'cs_customer_calls');
      const callDocRef = doc(callsRef, item.id);
      const callDoc = await getDoc(callDocRef);
      this.selectedItem = callDoc.data();
      this.callDialog = true;
    },
    async sendQuery() {
      if (!this.userQuery) return;

      this.assistantLoading = true;
      this.startTimer();
      this.history.push({ type: 'user', content: this.userQuery });

      // Log the query before sending the request
      await this.logQuery('assistant');

      try {
        // const endpoint = 'http://localhost:5000/hydro-ottawa-ai/northamerica-northeast1/geminicalls';
        const endpoint = 'https://northamerica-northeast1-hydro-ottawa-ai.cloudfunctions.net/geminicalls';
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userMessage: this.userQuery, sessionId: this.sessionId })
        });
        const data = await response.json();
        if (data.success) {
          const replacedContent = data.html.replace(/F\d{12}V\d{1}U\d{7}/g, (match) => {
            console.log('Match:', match);
            return `<a href="#" class="call-link" data-id="${match}">${match}</a>`;
          });
          this.history.push({ type: 'model', content: replacedContent, citations: data.citations });
        } else {
          this.history.push({ type: 'model', content: 'Error: ' + data.response });
        }
      } catch (error) {
        this.history.push({ type: 'model', content: 'Error: ' + error.message });
      } finally {
        this.assistantLoading = false;
        this.stopTimer();
        // Log the query again after receiving the response or handling an error
        await this.logQuery('assistant');
      }
      this.userQuery = '';
    },
    async logQuery(type) {
      const db = getFirestore();
      const logsRef = collection(db, 'queries');

      try {
        if (type === 'explorer') {
          const logDoc = {
            user: this.user.email,
            type,
            query: this.search || '', // Ensure query is not undefined
          };
          await addDoc(logsRef, logDoc);
        } else if (type === 'assistant') {
          const logDoc = {
            user: this.user.email,
            type,
            history: this.history.map(item => item.content), // Flattening the history array to just contain the content
          };
          if (this.assistantLogDocId) {
            // console.log('assistant update', this.assistantLogDocId, logDoc);
            // Update existing log document
            const logDocRef = doc(logsRef, this.assistantLogDocId);
            await setDoc(logDocRef, logDoc, { merge: true }); // Use merge to only update fields
          } else {
            // console.log('assistant create', logDoc);
            // Create new log document
            const logDocRef = await addDoc(logsRef, logDoc);
            this.assistantLogDocId = logDocRef.id;
          }
        }
      } catch (error) {
        console.error('Error logging query:', error);
      }
    },
    startTimer() {
      this.timer = 0;
      if (this.timerInterval) clearInterval(this.timerInterval);
      this.timerInterval = setInterval(() => {
        this.timer++;
      }, 1000);
    },
    stopTimer() {
      if (this.timerInterval) clearInterval(this.timerInterval);
    },
    async startConversation() {
      this.history = [];
      this.sessionId = Date.now().toString(36) + Math.random().toString(36); // Generate a new session ID for a new conversation
      this.assistantLogDocId = null; // Reset assistant log document ID for a new conversation
    },
    initialize() {
      // this.load(true); // Initial load only, fetching the first 500 records
      // this.filteredItems = this.items;
      // this.explorerLogDocId = null; // Reset explorer log document ID for a new search
      // this.loadCategories();
      // this.loadJourneys();
    },
    async loadCategories() {
      console.log('Loading categories...');
      const db = getFirestore();
      const categoriesRef = collection(db, 'cs_call_categories');
      const categoriesSnapshot = await getDocs(categoriesRef);

      let categories = categoriesSnapshot.docs.map(doc => ({
        id: doc.id, // Document ID
        ...doc.data() // Spread all other fields (name, calls, count)
      }));

      // Sort this.categories by count in descending order
      categories.sort((a, b) => b.count - a.count);
      this.categories = categories;

      console.log('Done.');
    },
    async loadJourneys() {
      console.log('Loading journeys...');
      const db = getFirestore();
      const journeysRef = collection(db, 'cs_call_journeys');
      const journeysSnapshot = await getDocs(journeysRef);

      let journeys = journeysSnapshot.docs.map(doc => ({
        id: doc.id, // Document ID
        ...doc.data() // Spread all other fields (name, calls, count)
      }));

      // Sort this.categories by count in descending order
      journeys.sort((a, b) => b.count - a.count);
      this.journeys = journeys;

      console.log('Done.');
    },
    generateImage() {
      const endpoint = 'http://localhost:5000/hydro-ottawa-ai/northamerica-northeast1/imagen';
      fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt: 'a successful business man' })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    improvements(value) {
      if (this.selectedItem) {
        let x = ''
        value.forEach(item => {
          x += "Suggestion: " + item.improvement + ', Logic: ' + item.reasoning + '; '
        });
        return x;
      }
    },
  },
  filters: {
    truncate(string) {
      let value = 80;
      return string?.length > value ? string.slice(0, value) + "..." : string;
    },
    joinArray(value) {
      return Array.isArray(value) ? value.join(', ') : 'N/A';
    },
  },
  watch: {
    user() {
      this.startConversation();
      this.initialize();
    },
    $route(to, from) {
      this.startConversation();
      this.initialize();
    }
  },
  mounted() {
    // Load initial 500 records
    this.initialize()
    this.$nextTick(() => {
      document.addEventListener('click', (event) => {
        if (event.target.classList.contains('call-link')) {
          event.preventDefault();
          const callId = event.target.getAttribute('data-id');
          this.openCallDialog({ id: callId });
        }
      });
    });
  }
})
</script>

<style lang="scss" scoped>
.message {
  .system-message {
    max-width: 80%;
    background: #f0f0f0;
    color: black;
    border-radius: 10px;
    text-align: left !important;
  }
  .user-message {
    max-width: 80%;
    background: #4caf50;
    color: white;
    border-radius: 10px;
  }
}
.citations {
  font-size: 0.875rem;
  color: #757575;
}
</style>
